import React from 'react';
import {
  ADMIN_LOGIN,
  TRAVELER_SIGN_UP,
  FORGOT_PASSWORD,
  SET_PASSWORD,
  LOGIN_FAILURE,
  FETCH_ORGANIZATION,
  FETCH_ALL_ORGANIZATIONS,
  CHECK_CURRENT_USER,
  SIGNUP_FAILURE,
  CLEAR_EMAIL_ERROR,
  SSO_LOGIN,
  FETCH_USER,
  RESET_AUTH_LOADING,
} from '../actions/types';

const initialState = {
  user: {},
  organization: {},
  organizations: [],
  isLoading: true,
  orgLoading: true,
  organizationLoading: true,
  signedIn: false,
  email: null,
  error: null,
  errors: null,
  client: null,
  user_id: null,
  token: null,
  allow_traveler_sign_up: true,
  admin_sign_in: null,
  client_id: null,
  subdomain: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_AUTH_LOADING:
      return {
        ...state,
        user: {},
        isLoading: true,
        orgLoading: true,
        organizationLoading: true,
        error: null,
        errors: null,
      };
    case ADMIN_LOGIN:
      return {
        ...state,
        user_id: action.payload.data.id,
        // token: action.payload.data.authentication_token,
        token: action.payload.data.token,
        // expires_at: action.payload.data.expires_at,
        email: action.payload.data.user,
        isLoading: false,
        signedIn: true,
        allow_traveler_sign_up: action.payload.data.allow_traveler_sign_up,
        clientFeatureList: action.payload.data.client_feature_list,
        admin_sign_in: action.payload.data.admin_sign_in,
        admin_access: action.payload.data.admin_access,
        tap_agreement: action.payload.data.tap_agreement,
        client_id: action.payload.data.client_account.id,
        subdomain: action.payload.data.subdomain,
        via_abroad: action.payload.data.via_abroad,
        via_international: action.payload.data.via_international,
        via_contracts: action.payload.data.via_contracts,
      };
    case SSO_LOGIN:
      return {
        ...state,
        isLoading: false,
        signedIn: true,
      };
    case TRAVELER_SIGN_UP:
      return {
        ...state,
        user: action.payload,
        user_id: action.payload.data.user.id,
        // token: action.payload.data.user.authentication_token,
        token: action.payload.data.user.token,
        email: action.payload.data.user.email,
        clientFeatureList: action.payload.data.client_feature_list,
        isLoading: false,
        signedIn: true,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case SET_PASSWORD:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        error: action.payload.error || 'Error: Invalid Request - Incorrect Email and/or Password',
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        error: 'Error: Email already exists!',
        errors: {
          form:
            'Error: An account already exists for this email address. Click the Sign in link below to sign in with your email or create a new account with a different email.',
        },
      };
    case FETCH_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
        customBranding: action.payload.branding_theme,
        organizationLoading: false,
      };
    case FETCH_ALL_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
        orgLoading: false,
      };
    case CHECK_CURRENT_USER:
      return {
        ...state,
        signedIn: action.signedIn,
        isLoading: false,
      };
    case CLEAR_EMAIL_ERROR:
      return {
        ...state,
        error: '',
      };
    case FETCH_USER:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
